import { useCallback, useMemo, useState } from "react";
import { showSnackbar } from "@/stores/snackbar";
import client from "@/utils/api/client";
import { LikeKind } from "@/web-client/api";
import useAnalytics from "@/hooks/useAnalytics";

export type UseLikeReturn = {
  likeHandler: VoidFunction;
  status: LikeStatus;
};

export enum LikeStatus {
  Default,
  Progress,
}

export type LikeableEntity = {
  id: number;
  has_liked: boolean;
  likes_count: number;
};

export type LikeMutateHandler<T> = (updatedData: T) => void;

const useLike = <T extends LikeableEntity>(
  target: T,
  kind: LikeKind,
  onUpdated: LikeMutateHandler<T>,
  makerId?: number,
): UseLikeReturn => {
  const [status, setStatus] = useState<LikeStatus>(LikeStatus.Default);
  const { event } = useAnalytics(makerId);

  const itemId = useMemo<string>(() => {
    switch (kind) {
      case LikeKind.PROJECT:
        return `PJ_${target.id}`;
      case LikeKind.POST:
        return `POST_${target.id}`;
      default:
        return `PRODV_${target.id}`;
    }
  }, [target.id, kind]);

  const likeHandler = useCallback(async () => {
    if (status === LikeStatus.Progress) return;

    try {
      setStatus(LikeStatus.Progress);

      if (!target.has_liked) {
        await client.likesPost({
          likeCreateParam: {
            entity_id: target.id,
            kind,
          },
        });
        onUpdated({
          ...target,
          has_liked: true,
          likes_count: target.likes_count + 1,
        });
        event("like", { item_id: itemId });
      } else {
        await client.likesDelete({
          likeDeleteParam: {
            entity_id: target.id,
            kind,
          },
        });
        onUpdated({
          ...target,
          has_liked: false,
          likes_count: target.likes_count - 1,
        });
      }
      setStatus(LikeStatus.Default);
    } catch {
      showSnackbar({
        message: "Likeに失敗しました",
        severity: "error",
      });
      setStatus(LikeStatus.Default);
    }
  }, [target, status, setStatus, onUpdated, kind, itemId, event]);

  return {
    likeHandler,
    status,
  };
};
export default useLike;
