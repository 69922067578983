type Props<T> = {
  organizedItems: T[][];
  columnNumber: number;
  onRender: RenderMasonryItem<T>;
  minColumn?: number;
  gap: number;
  containerRef: (node: HTMLDivElement) => void;
};

export type RenderMasonryItem<T> = (item: T, index: number) => JSX.Element;

const MasonryList = <T,>(props: Props<T>): JSX.Element => {
  const { organizedItems, columnNumber, onRender, gap, containerRef } = props;

  return (
    <div
      ref={containerRef}
      className="grid items-start"
      style={{
        gap: `${gap}px`,
        gridTemplateColumns: `repeat(${columnNumber}, 1fr)`,
      }}
    >
      {[...Array(columnNumber)].map((_, index: number) => (
        <div
          key={index}
          className="grid"
          style={{
            gap: `${gap}px`,
          }}
        >
          {organizedItems[index].map(onRender)}
        </div>
      ))}
    </div>
  );
};
export default MasonryList;
