import { FC, useMemo } from "react";
import { PostResponse } from "@/web-client/api";
import useDevice from "@/hooks/useDevice";
import useMasonry from "@/hooks/useMasonry";
import MasonryList, { RenderMasonryItem } from "@/components/MasonryList";

interface Props {
  posts: PostResponse[];
  onRender: RenderMasonryItem<PostResponse>;
}

const PostItemList: FC<Props> = ({ posts, onRender }): JSX.Element => {
  const { isPc } = useDevice();

  const itemMinWidth = useMemo<number>(() => {
    return isPc ? 320 : 200;
  }, [isPc]);

  const { items, numberOfColumns, containerRef, gap } = useMasonry(
    posts,
    isPc ? 16 : 8,
    2,
    itemMinWidth,
  );

  return (
    <MasonryList<PostResponse>
      containerRef={containerRef}
      organizedItems={items}
      columnNumber={numberOfColumns}
      onRender={onRender}
      gap={gap}
    />
  );
};

export default PostItemList;
