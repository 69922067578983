import Maybe from "@/components/Maybe";
import Image from "next/image";
import { FC, ReactNode } from "react";

type Props = {
  label?: ReactNode;
};

const Circular: FC<Props> = ({ label }): JSX.Element => {
  return (
    <div className="flex flex-col gap-8 justify-center items-center p-4">
      <Image width={27} height={27} src="/progress.gif" alt="" />
      <Maybe condition={!!label}>
        <p className="text-xs text-secondary">{label}</p>
      </Maybe>
    </div>
  );
};
export default Circular;
