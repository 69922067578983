import { ListItemKind } from "@/web-client/api";
import { ListItem } from "@/consts/ListItemType";
import useDisclosure from "@/hooks/useDisclosure";
import dynamic from "next/dynamic";
import { FC, PropsWithChildren, CSSProperties } from "react";
import RenderIfAuth from "@/components/RenderIfAuth";
import { RedirectReason } from "@/domain/values/RedirectReason";
import { useSignInRequiredAction } from "@/features/user_authentication";

const DynamicModalAddList = dynamic(
  () => import("@/features/list/components/ModalAddList"),
);

export interface AddListBtnWrapperProps {
  kind: ListItemKind;
  selectedItem: ListItem;
  style?: CSSProperties;
}

const AddListBtnWrapper: FC<PropsWithChildren<AddListBtnWrapperProps>> = ({
  selectedItem,
  kind,
  style,
  children,
}) => {
  const { isOpen, openHandler, closeHandler } = useDisclosure();
  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    reason: RedirectReason.AddList,
    action: openHandler,
  });

  return (
    <>
      <button style={style} onClick={executeAction} title={"リストに追加"}>
        {children}
      </button>
      <RenderIfAuth>
        <DynamicModalAddList
          isOpen={isOpen}
          closeHandler={() => closeHandler()}
          selectedItem={selectedItem}
          kind={kind}
        />
      </RenderIfAuth>
      {SignInModalComponent}
    </>
  );
};

export default AddListBtnWrapper;
