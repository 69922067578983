import { FC } from "react";
import IconLikeFill from "../../../assets/imgs/svg/icon-like-fill.svg";
import { theme } from "tailwind.config";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { RedirectReason } from "@/domain/values/RedirectReason";
import { DialogSignIn } from "@/features/user_authentication/components/DialogSignIn";

type Props = {
  hasLiked: boolean;
  onClick: VoidFunction;
};

const MinimumLikeBtn: FC<Props> = ({ hasLiked, onClick }) => {
  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    reason: RedirectReason.Like,
    action: onClick,
  });

  return (
    <>
      <button
        onClick={executeAction}
        title={`LIKEを${hasLiked ? "はずす" : "つける"}`}
      >
        <span
          className={[
            "block border-body-primary rounded-xs p-4 text-primary shadow-default hover:shadow-none transition duration-200",
            hasLiked ? "bg-like" : "bg-white",
          ].join(" ")}
        >
          <IconLikeFill
            width={20}
            height={20}
            fill={hasLiked ? theme.colors.white : theme.colors.gray[300]}
          />
        </span>
      </button>
      {SignInModalComponent}
    </>
  );
};

export default MinimumLikeBtn;
